<template>
  <transition
    enter-active-class="animated bounceInDown"
    leave-active-class="animated bounceOutRight"
  >
    <div v-show="isActive" v-bind:class="classToast">
      <div v-bind:class="classItem" role="alert" @click="close">
        <div class="v-toast-icon" v-if="icon !== ''">
          <ui-icon :value="icon" color="white" :size="'28'"></ui-icon>
        </div>
        <p class="v-toast-text">{{ text }}</p>
        <a
          v-for="(action, i) in actions"
          class="link"
          :key="i"
          @click="action.handler()"
        >
          {{ action.text }}
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
import { UiIcon } from "@/modules/ui/components";

export default {
  name: "ui-toast",
  components: { UiIcon },
  data() {
    return {
      isActive: false,
      classItem: `ui-toast-item v-toast v-toast-${this.type} is-${this.position}`,
      containerTop: null,
    };
  },
  props: {
    text: {
      type: String,
      required: false,
      default: "",
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "success",
    },
    duration: {
      type: [String, Number],
      required: false,
      default: 2000,
    },
    position: {
      type: [String],
      required: false,
      default: "bottom-right",
    },
    actions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  beforeMount() {
    this.setupContainer();
  },

  mounted() {
    this.show();
  },
  computed: {
    classToast() {
      if (this.position.includes("right")) {
        return "rightAlign";
      } else if (this.position.includes("left")) {
        return "leftAlign";
      }
      return "centerAlign";
    },
  },
  methods: {
    setupContainer() {
      let toastList = this.position.includes("top") ? "is-top" : "is-bottom";
      this.containerTop = document.querySelector(`.v-notify.${toastList}`);

      if (this.containerTop) {
        return;
      }

      this.containerTop = document.createElement("div");
      this.containerTop.className = `v-notify ${toastList}`;
      document.body.appendChild(this.containerTop);
    },

    show() {
      this.containerTop.insertAdjacentElement("afterbegin", this.$el);
      this.isActive = true;
      setTimeout(() => this.close(), this.duration);
    },
    close() {
      this.isActive = false;
      setTimeout(() => {
        this.$destroy();
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
@import "~animate.css/animate.min.css";

.link {
  margin-right: 10px;
  font-weight: bold;
}

.v-notify {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2em;
  overflow: hidden;
  z-index: 1052;
  pointer-events: none;
}

.v-notify .v-toast {
  display: inline-flex;
  align-items: center;
  animation-duration: 150ms;
  margin: 0.5em 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 0.25em;
  pointer-events: auto;
  opacity: 0.92;
  color: #fff;
  min-height: 3em;
  cursor: pointer;
}

.v-notify .v-toast .v-toast-text {
  margin: 0;
  padding: 0.5em 1em;
  word-break: break-word;
}

.v-notify .v-toast .v-toast-icon {
  display: none;
}

.v-notify .v-toast-success {
  background-color: #47d78a;
}

.v-notify .v-toast-info {
  background-color: #1c85d5;
}

.v-notify .v-toast-warning {
  background-color: #febc22;
}

.v-notify .v-toast-error {
  background-color: #f7471c;
}

.v-notify .v-toast-default {
  background-color: #343a40;
}

.v-notify .v-toast.is-top,
.v-notify .v-toast.is-bottom,
.centerAlign {
  align-self: center;
}

.v-notify .v-toast.is-top-right,
.v-notify .v-toast.is-bottom-right,
.rightAlign {
  align-self: flex-end;
}

.v-notify .v-toast.is-top-left,
.v-notify .v-toast.is-bottom-left,
.leftAlign {
  align-self: flex-start;
}

.v-notify.is-top {
  flex-direction: column;
}

.v-notify.is-bottom {
  flex-direction: column-reverse;
}

.v-notify.is-custom-parent {
  position: absolute;
}

@media screen and (max-width: 768px) {
  .v-notify {
    padding: 0;
    position: fixed !important;
  }
}

.v-notify .v-toast {
  opacity: 1;
  min-height: 4em;
}

.v-notify .v-toast .v-toast-text {
  padding: 1.5em 1em;
}

.v-notify .v-toast .v-toast-icon {
  display: block;
  width: 27px;
  min-width: 27px;
  height: 27px;
  margin-left: 1em;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(120px);
  opacity: 0;
}
</style>